/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useMemo, useEffect } from 'react';
import * as Constants from '../common/webconstants.js';
import { Link } from 'react-router-dom';
import Spinner from '../components/spinner.js';
import Center from '../components/center.js';
import SearchBox from "../components/searchbox.js";
import LeadsPageLayout from "../components/leadspagelayout.js";
import usePubSub from '../common/usePubSub.js';
import { JoinLeadCallButton, CallLeadButton } from '../components/leadcallbutton.js';
import { RemoveLeadButton } from '../components/removeleadbutton.js';
import useLocalStorage from '../common/useLocalStorage.js';
import API from '../common/api.js';
import Table from '../common/table.js';
import { useLeadsWithLoadMoreFunctionality } from '../common/useLeads.js';
import { formatDateTimeHumanDateTime } from '../common/formatting.js';
import LeadPriorityLabel from '../common/leadprioritylabel.js';
import usePinnedLeads from '../common/usePinnedLeads.js';
import useRecentlyViewedLeads from '../common/useRecentlyViewedLeads.js';
import IconDropdown, { DROPDOWN_POSITION_LEFT } from '../components/icondropdown.js';
import useLeadModelDescription from '../common/useLeadModelDescription.js';
import useOpportunityModelDescription from '../common/useOpportunityModelDescription.js';
import Select from 'react-select';
import LeadsPageCurrent from '../components/leadspage/leadspagecurrent.js';
import { LEAD_PRIO_LABELS } from '../common/webconstants.js';
var classNames = require('classnames');
var moment = require('moment');

const BATCH_SIZE = 20;
const QUEUED_LEADS_BATCH_SIZE = 200;

function Tab({ title, isActive, onClick, unseenContent }) {
    return (
        <a className={classNames("item", { active: isActive })} onClick={onClick}>
            {title}
            {unseenContent && (
                <div
                    style={{
                        width: 6,
                        height: 6,
                        borderRadius: 6,
                        backgroundColor: '#ff3000',
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                />
            )}
        </a>
    );
}

const FilterBar = ({
    query,
    setQuery,
    leadModelDescription,
    selectedAssigneeOptions,
    setSelectedAssigneeOptions,
    selectedCrAssigneeOptions,
    setSelectedCrAssigneeOptions,
    selectedPriorityOptions,
    setSelectedPriorityOptions,
    selectedBenefitsTypeOptions,
    setSelectedBenefitsTypeOptions,
    allUsers,
}) => {
    const anyFiltersApplied = query.length > 0
        || selectedAssigneeOptions.length > 0
        || selectedPriorityOptions.length > 0
        || selectedBenefitsTypeOptions.length > 0;

    const assigneeOptions = useMemo(() => {
        return allUsers
            .filter((user) => user.isAttorney)
            .map((user) => ({ value: user.user_id, label: user.name }));
    }, [allUsers]);

    const crAssigneeOptions = useMemo(() => {
        return allUsers
            .filter((user) => user.isCRAgent)
            .map((user) => ({ value: user.user_id, label: user.name }));
    }, [allUsers]);

    const priorityOptions = useMemo(() => {
        const originalOrder = Object
            .entries(LEAD_PRIO_LABELS)
            .map(([priorityValue, priorityLabel]) => ({
                label: priorityLabel,
                value: priorityValue,
            }));

        const presentableOrder = [...originalOrder.splice(1), originalOrder[0]];

        return presentableOrder;
    }, []);

    const clearFilters = () => {
        setQuery('')
        setSelectedAssigneeOptions([])
        setSelectedPriorityOptions([])
        setSelectedBenefitsTypeOptions([])
    };

    return (
        <>
            <SearchBox
                allPossibleValues={[]}
                onCommit={(serachboxsubmit) => setQuery(serachboxsubmit || '')}
                initialValue={query}
                placeholder="Filter by name or phone..."
                large
            />
            <Select
                options={leadModelDescription ? leadModelDescription.fields.benefitsType.options : []}
                placeholder="Filter by Benefits Type"
                className="min-w-64"
                value={selectedBenefitsTypeOptions}
                onChange={(selectedOptions) => setSelectedBenefitsTypeOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
                isDisabled={!leadModelDescription}
            />
            <Select
                options={priorityOptions}
                placeholder="Filter by priority"
                className="min-w-64"
                value={selectedPriorityOptions}
                onChange={(selectedOptions) => setSelectedPriorityOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
            />
            <Select
                options={assigneeOptions}
                placeholder="Filter by assignee"
                className="min-w-64"
                value={selectedAssigneeOptions}
                onChange={(selectedOptions) => setSelectedAssigneeOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
            />
            <Select
                options={crAssigneeOptions}
                placeholder="Filter by owner"
                className="min-w-64"
                value={selectedCrAssigneeOptions}
                onChange={(selectedOptions) => setSelectedCrAssigneeOptions(selectedOptions || [])}
                isClearable
                closeMenuOnSelect={false}
                isMulti
            />
            {anyFiltersApplied && (
                <div>
                    <button className="ui tertiary button" onClick={clearFilters}>
                        Reset filters
                    </button>
                </div>
            )}
        </>
    );
};

const FilterButton = ({ onClick, active, pressed }) => {
    return (
        <button
            className={classNames("ui basic icon button", { active })}
            onClick={onClick}
        >
            <i className={classNames("filter icon", { green: pressed })}/>
        </button>
    );
};

const CallBackButton = ({ lead, salesforceUrlForLead }) => {
    const [suggestedPhoneNumber, setSuggestedPhoneNumber] = useState(null);
    const [numberIsSanitized, setNumberIsSanitized] = useState(false);

    useEffect(() => {
        const loadSuggestedPhoneNumber = async () => {
            const loadedSuggestedPhoneNumber = await API.getSuggestedPhoneNumberForLead(lead.id);
            setSuggestedPhoneNumber(loadedSuggestedPhoneNumber.phoneNumber);
            setNumberIsSanitized(loadedSuggestedPhoneNumber.isSanitized);
        };

        loadSuggestedPhoneNumber();
    }, [lead.id])

    return (
        <CallLeadButton
            lead={lead}
            disabled={!suggestedPhoneNumber}
            number={suggestedPhoneNumber}
            leadUrl={salesforceUrlForLead}
            purpose={Constants.CALL_PURPOSES.POST_CONSULTATION}
            labelCallBack
            skipSanitizeNumber={numberIsSanitized}
        />
    );
};

const CallButton = ({ lead, salesforceUrlForLead }) => {
    if (lead.waitingInVonageConversation) {
        return (
            <JoinLeadCallButton
                lead={lead}
                leadUrl={salesforceUrlForLead}
            />
        );
    } else if (lead.isExpectingCallBackASAP) {
        return (
            <CallBackButton lead={lead} />
        );
    } else {
        return null;
    }
};

const LeadRow = ({ lead, allUsers, renderAsOpportunity }) => {
    const { reloadLeads } = useQueuedLeads();
    const usersById = useMemo(() => {
        const users = {};

        allUsers.forEach((user) => users[user.user_id] = user);

        return users;
    }, [allUsers]);

    const assigneeLabel = (userId) => {
        if (!userId) return '-';

        const user = usersById[userId];

        if (!user) return `User ${userId}`;

        return user.name;
    };

    const salesforceUrlForLead = useMemo(() => {
        if (lead.salesforceOpportunityId || (!lead.salesforceLeadId && lead.isConverted)) {
            return `https://pershing1234.lightning.force.com/lightning/r/Opportunity/${lead.salesforceOpportunityId}/view`
        }

        return `https://pershing1234.lightning.force.com/lightning/r/Lead/${lead.salesforceLeadId}/view`;
    }, [lead]);

    const renderContingency = (contingency) => {
        if (contingency === 'Yes') {
            return (<i className="check icon" style={{ color: '#3dc632' }} />);
        }
        if (contingency === 'No') {
            return (<i className="times icon" style={{ color: '#f70808' }} />);
        }

        return contingency;
    };

    return (
        <tr key={lead.id} className="task-row" style={{ height: 50 }}>
            <td className={classNames({'border-none': false})} style={{ width: 175 }}>
                {renderAsOpportunity ? lead.opportunityName : lead.name}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {renderAsOpportunity
                    ? lead.opportunityBenefitsTypeLabel || lead.opportunityBenefitsType || '-'
                    : lead.benefitsTypeLabel || lead.benefitsType || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.caseType ? lead.caseType : '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {renderAsOpportunity
                    ? lead.opportunityStage || '-'
                    : lead.status || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {assigneeLabel(lead.assigneeId)}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.consultRecommendation || '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {renderContingency(lead.contingency)}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {lead.consultFieldsUpdatedAt ? formatDateTimeHumanDateTime(lead.consultFieldsUpdatedAt) : '-'}
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                {assigneeLabel(lead.crAssigneeId)}
            </td>
            <td className={classNames("whitespace-no-wrap", {'border-none': false})}>
                <LeadPriorityLabel lead={lead} />
            </td>
            <td className={classNames("whitespace-no-wrap", {'border-none': false})} style={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
                <a
                    href={salesforceUrlForLead}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Open on Salesforce"
                    className="mini ui blue labeled icon button"
                >
                    <i className="external square alternate icon" />
                    SF
                </a>
                <CallButton
                    lead={lead}
                    salesforceUrlForLead={salesforceUrlForLead}
                />
            </td>
            <td className={classNames({'border-none': false})} style={{ width: 200 }}>
                <RemoveLeadButton lead={lead} reloadLeads={reloadLeads} />
            </td>
        </tr>
    );
};

const LeadsTable = ({
    leads,
    leadsErrorMessage,
    leadsInitialized,
    loadingLeads,
    moreLeadsAvailable,
    loadMoreLeads,
    allUsers,
    renderAsOpportunity,
}) => {
    return (
        <>
            {leadsErrorMessage && <div className="ui error message">{leadsErrorMessage}</div>}
            {!leadsInitialized ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                <Table
                    dim={loadingLeads}
                    head={
                        <>
                            <th>Name of Lead</th>
                            <th>Benefits Type</th>
                            <th>Case Type</th>
                            <th>{renderAsOpportunity ? 'Stage' : 'Status/Stage'}</th>
                            <th>Assigned Attorney</th>
                            <th>Recommendation</th>
                            <th>Contingency</th>
                            <th>Consultation info updated at</th>
                            <th>Owner</th>
                            <th>Priority</th>
                            <th></th>
                            <th></th>
                        </>
                    }
                >
                    {leads.length === 0 && (
                        <tr className="dim">
                            <td className="border-none" colSpan={4}>No leads...</td>
                        </tr>
                    )}
                    {leads.map((lead) => (
                        <LeadRow
                            key={lead.id}
                            lead={lead}
                            renderAsOpportunity={renderAsOpportunity ? true : lead.isConverted}
                            allUsers={allUsers}
                        />
                    ))}
                    {moreLeadsAvailable && (
                        <tr>
                            <td className="border-none" colSpan={12}>
                                <button className="ui compact fluid button" onClick={loadMoreLeads} disabled={loadingLeads}>
                                    Load more...
                                </button>
                            </td>
                        </tr>
                    )}
                </Table>
            )}
        </>
    );
};

const LeadsPageCustomerRelationsLayout = ({
    tabs,
    sortOrder,
    setSortOrder,
    anyFiltersApplied,
    children,
    filterBar,
}) => {
    const [showFilterBar, setShowFilterBar] = useState(anyFiltersApplied);
    const sortOptions = [
        { value: Constants.SORT_BY_PRIORITY_ASC, title: "Highest priority first" },
        { value: Constants.SORT_BY_PRIORITY_DESC, title: "Lowest priority first" },
    ];

    return (
        <LeadsPageLayout
            tabs={tabs}
            showFilterBar={showFilterBar}
            filterBar={filterBar}
            actionButtons={
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="ui basic icon buttons" style={{marginRight: 10}}>
                        <IconDropdown
                            icon="sort amount down"
                            items={sortOptions}
                            value={sortOrder}
                            onChange={(option) => setSortOrder(option)}
                            position={DROPDOWN_POSITION_LEFT}
                        />
                        <FilterButton
                            onClick={() => setShowFilterBar(!showFilterBar)}
                            active={showFilterBar}
                            pressed={anyFiltersApplied}
                        />
                    </div>
                    <Link to="/add_lead_cr" className="ui labeled primary icon button">
                        <i className="plus icon" />
                        Add Lead
                    </Link>
                </div>
            }
        >
            {children}
        </LeadsPageLayout>
    );
};

const useFilters = ({ tabNamespace, defaultFilters }) => {
    const [sortOrder, setSortOrder] = useLocalStorage(`${tabNamespace}.sortOrder`, Constants.SORT_BY_PRIORITY_ASC);
    const [selectedAssigneeFilterOptions, setSelectedAssigneeFilterOptions] = useLocalStorage(`${tabNamespace}.selectedAssigneeFilterOptions`, []);
    const [selectedCrAssigneeFilterOptions, setSelectedCrAssigneeFilterOptions] = useLocalStorage(`${tabNamespace}.selectedCrAssigneeFilterOptions`, []);
    const [selectedOpportunityBenefitsTypeFilterOptions, setSelectedOpportunityBenefitsTypeFilterOptions] = useLocalStorage(`${tabNamespace}.selectedOpportunityBenefitsTypeFilterOptions`, []);
    const [selectedPriorityFilterOptions, setSelectedPriorityFilterOptions] = useLocalStorage(`${tabNamespace}.selectedPriorityFilterOptions`, []);
    const [selectedBenefitsTypeFilterOptions, setSelectedBenefitsTypeFilterOptions] = useLocalStorage(`${tabNamespace}.selectedBenefitsTypeFilterOptions`, []);
    const [queryFilter, setQueryFilter] = useLocalStorage(`${tabNamespace}.queryFilter`, '');
    const assigneeIdInFilter = useMemo(() => {
        return selectedAssigneeFilterOptions
            .map((selectedAssigneeOption) => selectedAssigneeOption.value);
    }, [selectedAssigneeFilterOptions]);
    const crAssigneeIdInFilter = useMemo(() => {
        return selectedCrAssigneeFilterOptions
            .map((selectedAssigneeOption) => selectedAssigneeOption.value);
    }, [selectedCrAssigneeFilterOptions]);
    const priorityInFilter = useMemo(() => {
        return selectedPriorityFilterOptions
            .map((selectedPriorityFilterOption) => selectedPriorityFilterOption.value);
    }, [selectedPriorityFilterOptions]);
    const benefitsTypeInFilter = useMemo(() => {
        return selectedBenefitsTypeFilterOptions
            .map((selectedBenefitsTypeFilterOption) => selectedBenefitsTypeFilterOption.value);
    }, [selectedBenefitsTypeFilterOptions]);
    const opportunityBenefitsTypeInFilter = useMemo(() => {
        return selectedOpportunityBenefitsTypeFilterOptions
            .map((selectedBenefitsTypeFilterOption) => selectedBenefitsTypeFilterOption.value);
    }, [selectedOpportunityBenefitsTypeFilterOptions]);
    const filters = useMemo(() => ({
        query: queryFilter,
        assigneeIdIn: assigneeIdInFilter,
        crAssigneeIdIn: crAssigneeIdInFilter,
        priorityIn: priorityInFilter,
        benefitsTypeIn: benefitsTypeInFilter,
        opportunityBenefitsTypeIn: opportunityBenefitsTypeInFilter,
        ...defaultFilters,
    }), [
        queryFilter,
        assigneeIdInFilter,
        crAssigneeIdInFilter,
        opportunityBenefitsTypeInFilter,
        priorityInFilter,
        benefitsTypeInFilter,
        defaultFilters,
    ]);

    const anyFiltersApplied = queryFilter.length > 0
        || assigneeIdInFilter.length > 0
        || crAssigneeIdInFilter.length >0
        || priorityInFilter.length > 0
        || benefitsTypeInFilter.length > 0;

    return {
        filters,
        anyFiltersApplied,
        sortOrder,
        setSortOrder,
        selectedAssigneeFilterOptions,
        setSelectedAssigneeFilterOptions,
        selectedCrAssigneeFilterOptions,
        setSelectedCrAssigneeFilterOptions,
        selectedPriorityFilterOptions,
        setSelectedPriorityFilterOptions,
        selectedBenefitsTypeFilterOptions,
        setSelectedBenefitsTypeFilterOptions,
        selectedOpportunityBenefitsTypeFilterOptions,
        setSelectedOpportunityBenefitsTypeFilterOptions,
        queryFilter,
        setQueryFilter,
    };
};

const LeadsPageCustomerRelationsTabDenied = ({
    allUsers,
    leadModelDescription,
    tabs,
}) => {
    const [filterBarHeight, setFilterBarHeight] = useState(0);
    const defaultFilters = useMemo(() => ({
        consultRecommendationIn: ['Deny It'],
        createdAtAfter: moment().add(-14, 'days'),
        isConverted: false,
    }), []);
    const {
        filters,
        anyFiltersApplied,
        sortOrder,
        setSortOrder,
        selectedAssigneeFilterOptions,
        setSelectedAssigneeFilterOptions,
        selectedCrAssigneeFilterOptions,
        setSelectedCrAssigneeFilterOptions,
        selectedPriorityFilterOptions,
        setSelectedPriorityFilterOptions,
        selectedBenefitsTypeFilterOptions,
        setSelectedBenefitsTypeFilterOptions,
        queryFilter,
        setQueryFilter,
    } = useFilters({
        tabNamespace: 'leadspagecustomerrelations.denied',
        defaultFilters,
    });
    const {
        leads,
        loading: loadingLeads,
        leadsErrorMessage,
        initialized: leadsInitialized,
        loadMore: loadMoreLeads,
        moreAvailable: moreLeadsAvailable,
        reload: reloadLeads,
    } = useLeadsWithLoadMoreFunctionality({
        batchSize: BATCH_SIZE,
        sortOrder,
        filters,
    });

    usePubSub({
        topic: Constants.PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED,
        messageHandler: (_topic, _data) => {
            reloadLeads();
        },
    });

    return (
        <LeadsPageCustomerRelationsLayout
            setFilterBarHeight={setFilterBarHeight}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            anyFiltersApplied={anyFiltersApplied}
            tabs={tabs}
            filterBar={
                <FilterBar
                    allUsers={allUsers}
                    query={queryFilter}
                    setQuery={setQueryFilter}
                    leadModelDescription={leadModelDescription}
                    selectedAssigneeOptions={selectedAssigneeFilterOptions}
                    setSelectedAssigneeOptions={setSelectedAssigneeFilterOptions}
                    selectedCrAssigneeOptions={selectedCrAssigneeFilterOptions}
                    setSelectedCrAssigneeOptions={setSelectedCrAssigneeFilterOptions}
                    selectedPriorityOptions={selectedPriorityFilterOptions}
                    setSelectedPriorityOptions={setSelectedPriorityFilterOptions}
                    selectedBenefitsTypeOptions={selectedBenefitsTypeFilterOptions}
                    setSelectedBenefitsTypeOptions={setSelectedBenefitsTypeFilterOptions}
                />
            }
        >
            <LeadsTable
                leads={leads}
                leadsErrorMessage={leadsErrorMessage}
                leadsInitialized={leadsInitialized}
                loadingLeads={loadingLeads}
                moreLeadsAvailable={moreLeadsAvailable}
                loadMoreLeads={loadMoreLeads}
                filterBarHeight={filterBarHeight}
                allUsers={allUsers}
            />
        </LeadsPageCustomerRelationsLayout>
    );
};

const LeadsPageCustomerRelationsTabConverted = ({
    allUsers,
    opportunityModelDescription,
    tabs,
}) => {
    const defaultFilters = useMemo(() => ({
        isConverted: true,
        opportunityStageNotIn: ['Closed Won', 'Closed Lost'],
        opportunityPresent: true,
    }), []);
    const {
        filters,
        anyFiltersApplied,
        sortOrder,
        setSortOrder,
        selectedAssigneeFilterOptions,
        setSelectedAssigneeFilterOptions,
        selectedCrAssigneeFilterOptions,
        setSelectedCrAssigneeFilterOptions,
        selectedPriorityFilterOptions,
        setSelectedPriorityFilterOptions,
        selectedOpportunityBenefitsTypeFilterOptions,
        setSelectedOpportunityBenefitsTypeFilterOptions,
        queryFilter,
        setQueryFilter,
    } = useFilters({
        tabNamespace: 'leadspagecustomerrelations.opportunities',
        defaultFilters,
    });
    const {
        leads,
        loading: loadingLeads,
        leadsErrorMessage,
        initialized: leadsInitialized,
        loadMore: loadMoreLeads,
        moreAvailable: moreLeadsAvailable,
        reload: reloadLeads,
    } = useLeadsWithLoadMoreFunctionality({
        batchSize: BATCH_SIZE,
        sortOrder,
        filters,
    });

    usePubSub({
        topic: Constants.PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED,
        messageHandler: (_topic, _data) => {
            reloadLeads();
        },
    });

    return (
        <LeadsPageCustomerRelationsLayout
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            anyFiltersApplied={anyFiltersApplied}
            tabs={tabs}
            filterBar={
                <FilterBar
                    allUsers={allUsers}
                    query={queryFilter}
                    setQuery={setQueryFilter}
                    leadModelDescription={opportunityModelDescription}
                    selectedAssigneeOptions={selectedAssigneeFilterOptions}
                    setSelectedAssigneeOptions={setSelectedAssigneeFilterOptions}
                    selectedCrAssigneeOptions={selectedCrAssigneeFilterOptions}
                    setSelectedCrAssigneeOptions={setSelectedCrAssigneeFilterOptions}
                    selectedPriorityOptions={selectedPriorityFilterOptions}
                    setSelectedPriorityOptions={setSelectedPriorityFilterOptions}
                    selectedBenefitsTypeOptions={selectedOpportunityBenefitsTypeFilterOptions}
                    setSelectedBenefitsTypeOptions={setSelectedOpportunityBenefitsTypeFilterOptions}
                />
            }
        >
            <LeadsTable
                leads={leads}
                leadsErrorMessage={leadsErrorMessage}
                leadsInitialized={leadsInitialized}
                loadingLeads={loadingLeads}
                moreLeadsAvailable={moreLeadsAvailable}
                loadMoreLeads={loadMoreLeads}
                allUsers={allUsers}
                renderAsOpportunity
            />
        </LeadsPageCustomerRelationsLayout>
    );
};

const useQueuedLeads = () => {
    const defaultFilters = useMemo(() => ({
        orOperator: [
            { isOnHold: true },
            { isExpectingCallBackASAP: true },
        ],
    }), []);
    const filterSettings = useFilters({
        tabNamespace: 'leadspagecustomerrelations.queued',
        defaultFilters,
    });
    const {
        leads,
        loading: loadingLeads,
        leadsErrorMessage,
        initialized: leadsInitialized,
        loadMore: loadMoreLeads,
        moreAvailable: moreLeadsAvailable,
        reload: reloadLeads,
    } = useLeadsWithLoadMoreFunctionality({
        batchSize: QUEUED_LEADS_BATCH_SIZE,
        sortOrder: filterSettings.sortOrder,
        filters: filterSettings.filters,
    });

    usePubSub({
        topic: Constants.PUBSUB_TOPIC_LEAD_WAITING_IN_CONVERSATION_UPDATED,
        messageHandler: (_topic, _data) => {
            reloadLeads();
        },
    });

    return {
        leads,
        loadingLeads,
        leadsErrorMessage,
        leadsInitialized,
        loadMoreLeads,
        moreLeadsAvailable,
        filterSettings,
        reloadLeads,
    };
}

const LeadsPageCustomerRelationsTabQueued = ({
    allUsers,
    opportunityModelDescription,
    tabs,
    leads,
    loadingLeads,
    leadsErrorMessage,
    leadsInitialized,
    loadMoreLeads,
    moreLeadsAvailable,
    filterSettings,
}) => {
    const {
        anyFiltersApplied,
        sortOrder,
        setSortOrder,
        selectedAssigneeFilterOptions,
        setSelectedAssigneeFilterOptions,
        selectedCrAssigneeFilterOptions,
        setSelectedCrAssigneeFilterOptions,
        selectedPriorityFilterOptions,
        setSelectedPriorityFilterOptions,
        selectedOpportunityBenefitsTypeFilterOptions,
        setSelectedOpportunityBenefitsTypeFilterOptions,
        queryFilter,
        setQueryFilter,
    } = filterSettings;

    return (
        <LeadsPageCustomerRelationsLayout
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            anyFiltersApplied={anyFiltersApplied}
            tabs={tabs}
            filterBar={
                <FilterBar
                    allUsers={allUsers}
                    query={queryFilter}
                    setQuery={setQueryFilter}
                    leadModelDescription={opportunityModelDescription}
                    selectedAssigneeOptions={selectedAssigneeFilterOptions}
                    setSelectedAssigneeOptions={setSelectedAssigneeFilterOptions}
                    selectedCrAssigneeOptions={selectedCrAssigneeFilterOptions}
                    setSelectedCrAssigneeOptions={setSelectedCrAssigneeFilterOptions}
                    selectedPriorityOptions={selectedPriorityFilterOptions}
                    setSelectedPriorityOptions={setSelectedPriorityFilterOptions}
                    selectedBenefitsTypeOptions={selectedOpportunityBenefitsTypeFilterOptions}
                    setSelectedBenefitsTypeOptions={setSelectedOpportunityBenefitsTypeFilterOptions}
                />
            }
        >
            <LeadsTable
                leads={leads}
                leadsErrorMessage={leadsErrorMessage}
                leadsInitialized={leadsInitialized}
                loadingLeads={loadingLeads}
                moreLeadsAvailable={moreLeadsAvailable}
                loadMoreLeads={loadMoreLeads}
                allUsers={allUsers}
            />
        </LeadsPageCustomerRelationsLayout>
    );
};

export default function LeadsPageCustomerRelations({ allUsers, expandedLeadID, allTeams, initialTab }) {
    const [activeTab, setActiveTab] = useLocalStorage('leadspagecustomerrelations.activeTab', Constants.LEADS_CR_CONSULTATIONS_TAB);
    const { leadModelDescription } = useLeadModelDescription();
    const { opportunityModelDescription } = useOpportunityModelDescription();
    const {
        pinnedLeads,
        loadingPinnedLeads,
        pinnedLeadsErrorMessage,
        togglePinLead,
        leadIsPinned,
    } = usePinnedLeads()
    const {
        recentlyViewedLeads,
        reportLeadViewed,
    } = useRecentlyViewedLeads();
    const {
        leads: queuedLeads,
        loadingLeads: loadingQueuedLeads,
        leadsErrorMessage: queuedLeadsErrorMessage,
        leadsInitialized: queuedLeadsInitialized,
        loadMoreLeads: loadMoreQueuedLeads,
        moreLeadsAvailable: moreQueuedLeadsAvailable,
        filterSettings: queuedLeadsFilterSettings,
    } = useQueuedLeads();
    useEffect(() => {
        if (expandedLeadID) {
            setActiveTab(Constants.LEADS_CR_CONSULTATIONS_TAB);
        }
    }, [expandedLeadID]);
    useEffect(() => {
        if (initialTab) {
            setActiveTab(initialTab);
        }
    }, [initialTab, setActiveTab]);
    const queuedLeadsExist = useMemo(() => queuedLeads.length > 0, [queuedLeads]);
    const tabs = (
        <>
            <Tab
                title="Consultations"
                isActive={activeTab === Constants.LEADS_CR_CONSULTATIONS_TAB}
                onClick={() => setActiveTab(Constants.LEADS_CR_CONSULTATIONS_TAB)}
            />
            <Tab
                title="Opportunities"
                isActive={activeTab === Constants.LEADS_CR_OPPORTUNITIES_TAB}
                onClick={() => setActiveTab(Constants.LEADS_CR_OPPORTUNITIES_TAB)}
            />
            <Tab
                title="Queued"
                isActive={activeTab === Constants.LEADS_CR_QUEUED_TAB}
                onClick={() => setActiveTab(Constants.LEADS_CR_QUEUED_TAB)}
                unseenContent={queuedLeadsExist}
            />
            <Tab
                title="Denied"
                isActive={activeTab === Constants.LEADS_CR_DENIED_TAB}
                onClick={() => setActiveTab(Constants.LEADS_CR_DENIED_TAB)}
            />
        </>
    );

    if (activeTab === Constants.LEADS_CR_OPPORTUNITIES_TAB) {
        return (
            <LeadsPageCustomerRelationsTabConverted
                allUsers={allUsers}
                opportunityModelDescription={opportunityModelDescription}
                tabs={tabs}
            />
        );
    } else if (activeTab === Constants.LEADS_CR_CONSULTATIONS_TAB) {
        const leadsUrl = '/leads_cr';
        const leadsArchiveUrl = `${leadsUrl}/archive`;
        const buildLeadUrl = (leadId) => `${leadsUrl}/${leadId}`;
        const newLeadUrl = '/add_lead_cr';

        return (
            <LeadsPageCurrent
                allUsers={allUsers}
                allTeams={allTeams}
                leadModelDescription={leadModelDescription}
                opportunityModelDescription={opportunityModelDescription}
                tabs={tabs}
                expandedLeadID={expandedLeadID}
                pinnedLeads={pinnedLeads}
                loadingPinnedLeads={loadingPinnedLeads}
                pinnedLeadsErrorMessage={pinnedLeadsErrorMessage}
                togglePinLead={togglePinLead}
                leadIsPinned={leadIsPinned}
                recentlyViewedLeads={recentlyViewedLeads}
                reportLeadViewed={reportLeadViewed}
                buildLeadUrl={buildLeadUrl}
                leadsArchiveUrl={leadsArchiveUrl}
                leadsUrl={leadsUrl}
                newLeadUrl={newLeadUrl}
            />
        );
    } else if (activeTab === Constants.LEADS_CR_QUEUED_TAB) {
        return (
            <LeadsPageCustomerRelationsTabQueued
                allUsers={allUsers}
                opportunityModelDescription={opportunityModelDescription}
                tabs={tabs}
                leads={queuedLeads.filter((l) => l.waitingInVonageConversation || l.isExpectingCallBackASAP)}
                loadingLeads={loadingQueuedLeads}
                leadsErrorMessage={queuedLeadsErrorMessage}
                leadsInitialized={queuedLeadsInitialized}
                loadMoreLeads={loadMoreQueuedLeads}
                moreLeadsAvailable={moreQueuedLeadsAvailable}
                filterSettings={queuedLeadsFilterSettings}
            />
        );
    } else {
        return (
            <LeadsPageCustomerRelationsTabDenied
                allUsers={allUsers}
                leadModelDescription={leadModelDescription}
                tabs={tabs}
            />
        );
    }
}
